<template>
  <div class="video-container">
    <video
      id="mainVideo"
      :src="currentVideo"
      class="w-full pointer-events-none user-select-none"
      autoplay
      playsinline
      loop
    ></video>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      plateOptions: [
        {
          default: "/videos/Plate1.mp4",
          square: "/videos/Plate1Square.mp4",
        },
        {
          default: "/videos/Plate2.mp4",
          square: "/videos/Plate2Square.mp4",
        },
        {
          default: "/videos/Plate3.mp4",
          square: "/videos/Plate3Square.mp4",
        },
      ],
      video: null,
      mobileFlag: false,
      currentPlate: null,
      currentVideo: "",
    };
  },
  methods: {
    updateVideo: function() {
      var mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches && !this.mobileFlag) {
        this.mobileFlag = true;
        this.currentVideo = this.currentPlate.square;
      } else if (!mediaQuery.matches && this.mobileFlag) {
        this.mobileFlag = false;
        this.currentVideo = this.currentPlate.default;
      }
      this.$forceUpdate();
    },
  },
  // watch: {
  //   currentPlate: function(newVal, oldVal) {
  //     if (newVal !== oldVal) {
  //       //
  //     }
  //   },
  // },
  created() {
    window.addEventListener("resize", this.updateVideo);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateVideo);
  },
  mounted() {
    const idx = Math.floor(Math.random() * this.plateOptions.length);
    this.currentPlate = this.plateOptions[idx];
    this.currentVideo = this.currentPlate.default;
    this.updateVideo();

    this.video = document.getElementById("mainVideo");
    this.video.addEventListener(
      "canplaythrough",
      function() {
        this.video.muted = true;
        this.video.play();
      }.bind(this)
    );
  },
};
</script>
<style lang="scss" scoped>
.video-container {
  max-width: 1266px;
  //   width: calc(900 / 1024 * 100vw);
  //   height: calc((900 / 1024 * 100vw) * (505 / 900));
  width: 100%;
  //   padding-top: 56.25%;
  overflow: visible;
  //   background: #f04521;
}
</style>
