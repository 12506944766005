<template>
  <div class="relative video-container flex">
    <img src="@/assets/images/home/vid_thumbnail.jpg" class="relative w-full h-auto" alt="">
    <iframe
      id="video-player"
      class="absolute top-0 left-0 w-full h-full"
      src="https://player.vimeo.com/video/524353675"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      mobileFlag: false,
      //   currentVideo: "/videos/Comp.mp4",
      //   wideVideo: "/videos/Comp.mp4",
      //   mobileVideo: "/videos/CompSquare.mp4",
    };
  },
  methods: {
    updateVideo: function() {
      var mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches && !this.mobileFlag) {
        this.mobileFlag = true;
        // this.currentVideo = this.mobileVideo;
      } else if (!mediaQuery.matches && this.mobileFlag) {
        this.mobileFlag = false;
        // this.currentVideo = this.wideVideo;
      }
    },
  },
  mounted() {},
  created() {
    this.updateVideo();
    window.addEventListener("resize", this.updateVideo);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateVideo);
  },
};
</script>
<style lang="scss" scoped>
#video-player {
  width: 100%;
  height: calc(100% * 0.5625);
}
iframe {
  min-width: 100%;
  min-height: 100%;
}
.video-container {
  max-width: 1266px;
  width: 100%;
  overflow: visible;
}
</style>
