<template>
  <div id="app" class="relative mx-auto mb-0 max-w-1440 w-full min-h-screen max-h-full">
    <Home></Home>
    <router-view class="z-50" />
  </div>
</template>
<script>
import Home from "./views/Home.vue";

export default {
  components: {
    Home,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,300;1,400&display=swap");
@import "@/assets/css/helpers.scss";

#app {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  border-bottom: 28px solid $red;
}
.page {
  h2,
  p,
  a {
    color: $textsecondary;
  }
}
.max-w-1440 {
  max-width: 90em;
}
</style>
