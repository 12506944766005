import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: null,
  // },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/trailer",
    name: "Trailer",
    component: () => import("../views/Trailer.vue"),
  },
  {
    path: "/credits",
    name: "Credits",
    component: () => import("../views/Credits.vue"),
  },
  {
    path: "/stills",
    name: "Stills",
    component: () => import("../views/Stills.vue"),
  },
  {
    path: "/music",
    name: "Music",
    component: () => import("../views/Music.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
