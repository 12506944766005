<template>
  <div class="home relative w-full bg-green mx-auto md:pt-20 pt-20 md:px-20 px-14">
    <div class="relative inline-block m-auto">
      <div class="relative z-10">
        <header class="menu">
          <nav class="absolute top-0 left-0 w-full h-full black md:text-5xl">
            <div
              @mouseover="navactive.about = true"
              @mouseout="navactive.about = false"
              id="nav-about"
              class="absolute align-bottom"
            >
              <router-link to="/about">ABOUT</router-link>
            </div>
            <div
              @mouseover="navactive.credits = true"
              @mouseout="navactive.credits = false"
              id="nav-credits"
              class="absolute transform rotate-90 align-bottom"
            >
              <router-link to="/credits">CREDITS</router-link>
            </div>
            <div
              @mouseover="navactive.stills = true"
              @mouseout="navactive.stills = false"
              id="nav-stills"
              class="absolute text-right align-bottom"
            >
              <router-link to="/stills">STILLS</router-link>
            </div>
            <div
              @mouseover="navactive.music = true"
              @mouseout="navactive.music = false"
              id="nav-music"
              class="absolute transform -rotate-90 align-bottom"
            >
              <router-link to="/music">MUSIC</router-link>
            </div>
            <div class="w-full z-10">
              <div id="nav-play" class="absolute w-full text-center align-bottom">
                <a @click.prevent="playTrailer" class="cursor-pointer"><span class="green">PLAY</span></a>
              </div>
            </div>
          </nav>
        </header>
        <VideoPlayer :class="{ 'opacity-0': showTrailer }"></VideoPlayer>
        <div v-show="showTrailer" class="absolute top-0 left-0 w-full h-full z-30">
          <div class="video-container flex absolute top-0 left-0 w-full h-full">
            <iframe
              id="video-player"
              :src="trailerSource"
              width="100%"
              height="100%"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <a @click.prevent="stopTrailer" class="absolute top-2 left-1/2 z-10 inline-block"
            ><CloseButton></CloseButton
          ></a>
        </div>
        <div
          v-show="!showTrailer"
          class="absolute top-0 left-0 w-full h-full flex user-select-none pointer-events-none"
        >
          <img
            src="@/assets/images/home/title.png"
            class="landscape-only"
            width="100%"
            height="auto"
            alt="the last days of san jose title treatment"
          />
          <img
            src="@/assets/images/home/title-portrait.png"
            class="portrait-only"
            width="100%"
            height="auto"
            alt="the last days of san jose title treatment"
          />
        </div>
        <div class="roll-ani w-full">
          <div v-show="navactive.about" class="absolute top-0 left-0">
            <img src="@/assets/images/home/about_rollover.gif" alt="" />
          </div>
          <div v-show="navactive.credits" class="absolute top-0 right-0">
            <img src="@/assets/images/home/credits_rollover.gif" alt="" />
          </div>
          <div v-show="navactive.stills" class="absolute bottom-0 right-0">
            <img src="@/assets/images/home/stills_rollover.gif" alt="" />
          </div>
          <div v-show="navactive.music" class="absolute bottom-0 left-0">
            <img src="@/assets/images/home/music_rollover.gif" alt="" />
          </div>
        </div>
      </div>
      <div id="main" class="relative mx-auto lg:px-40 md:px-0 pb-10">
        <div class="relative w-full">
          <p>
            The documentary tells the story of a real estate deal's unexpected detour — one that sparked the gentrification of Austin’s hippest neighborhood — and reveals layers of knotty, unanswerable questions, all more relevant than ever today.
          </p>
          <h4 class="font-extrabold text-xl pt-4">Premiering SXSW 2021</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vimeo from "@vimeo/player";
import CloseButton from "@/components/closeButton.vue";
import VideoEmbed from "@/components/VideoEmbed.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  name: "Home",
  components: { CloseButton, VideoPlayer, VideoEmbed },
  data() {
    return {
      showTrailer: false,
      trailerURL: "https://player.vimeo.com/video/524353675",
      trailerSource: "",
      player: null,
      navactive: {
        about: false,
        credits: false,
        stills: false,
        music: false,
      },
    };
  },
  mounted() {},
  watch: {
    showTrailer: function(newVal, oldVal) {
      // console.log(oldVal, newVal);
      if (newVal !== oldVal) {
        this.trailerSource = newVal
          ? this.trailerURL + (window.matchMedia("(max-width: 768px)").matches ? "?playsinline=0" : "")
          : "";
      }
    },
    $route(to, from) {
      if (to !== from) this.showTrailer = false;
    },
  },
  methods: {
    playTrailer: function() {
      this.showTrailer = true;
    },
    stopTrailer: function() {
      this.showTrailer = false;
    },
    onTrailerEnded: function() {
      this.showTrailer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/helpers.scss";
.bg-red {
  background: $red;
}
nav {
  color: #2e2e2e;
  font-weight: 900;
  @media screen and ($mobilewidth) {
    font-size: 2em;
    line-height: 2em;
  }
  #nav-about {
    top: -1em;
    @media screen and ($mobilewidth) {
      top: -1.53em;
    }
  }
  #nav-credits {
    right: -2.58em;
    top: 1.5em;
    @media screen and ($mobilewidth) {
      right: -2.6em;
      top: 1.05em;
    }
  }
  #nav-stills {
    bottom: -1.02em;
    right: 0em;
    @media screen and ($mobilewidth) {
      bottom: -1.518em;
      right: 0em;
    }
  }
  #nav-music {
    left: -2.085em;
    bottom: 1.03em;
    @media screen and ($mobilewidth) {
      left: -2.125em;
      bottom: 0.53em;
    }
  }
  #nav-play {
    bottom: 25px;
    .green {
      color: $green;
      @media screen and (hover: hover) {
        &:hover {
          color: $red;
        }
      }
    }

    @media screen and ($mobilewidth) {
      bottom: 0em;
    }
  }
  @media screen and (hover: hover) {
    a:hover {
      color: $red;
    }
  }
}
#main {
  max-width: 1266px;
  padding-top: 75px;

  &.lg\:px-40 {
    padding-left: 11rem;
    padding-right: 11rem;
    @media screen and ($mobilewidth) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  @media screen and ($mobilewidth) {
    max-width: 100%;
  }
}
.roll-ani {
  @media screen and ($mobilewidth) {
    display: none;
  }
}
//VIDEO PLAYER
#video-player {
  width: 100%;
  height: calc(100% * 0.5625);
}
iframe {
  min-width: 100%;
  min-height: 100%;
}
.video-container {
  max-width: 1266px;
  width: 100%;
  overflow: visible;
}
</style>
