<template>
  <a class="closebtn relative cursor-pointer inline-block">
    <svg
      class="pointer-events-none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="34px"
      height="34px"
      overflow="visible"
    >
      <path
        d="M17,0C7.6,0,0,7.6,0,17s7.6,17,17,17c9.4,0,17-7.6,17-17S26.4,0,17,0z M28.7,26.3l-9.6-9.6l6.8-7.3l-1.5-1.4	l-6.7,7.2l-7.2-7.2L9.1,9.5l7.3,7.3l-6.8,7.3l1.5,1.4l6.7-7.2l9.6,9.6C24.7,30.4,21,32,17,32C8.7,32,2,25.3,2,17C2,8.7,8.7,2,17,2	s15,6.7,15,15C32,20.5,30.8,23.8,28.7,26.3z"
        fill="#f2ecde"
      />
    </svg>
  </a>
</template>
<script>
export default {};
</script>
<style lang="scss" scope>
.closebtn {
  transform-origin: center center;
  transition: transform 0.55s ease;
  @media screen and (hover: hover) {
    &:hover {
      transform: rotate(-15deg);
    }
  }
}
</style>
